<style lang="less" scoped>
.item-block {
  min-width: 300px;
  width: 25%;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  .wrap {
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #FFF;
  }
}
.js {
  padding: 4px;
  border-radius: 2px;
  background: #eee;
}
.item-block {
  .bar {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 20px;
    }
    .iconfont {
      cursor: pointer;
      font-size: 25px;
      font-weight: 800;
    }
  }
  .info {
    margin: 10px 0 20px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  .action {
    display: flex;
    justify-content: space-around;
  }
}
</style>

<style lang="less" scoped>
.action {
  display: flex;
  flex-wrap: wrap;
  .btn {
    background-color: #F8F8F8;
    font-size: 12px;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    padding: 2px 5px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all .3s;
    &:hover, &:active {
      background-color: #ddd;
    }
    & + .btn {
      margin-left: 5px;
    }
  }
}
</style>

<template>
  <div class="item-block">
    <div class="wrap">
      <div class="bar">
        <span class="status">{{data.statusText}}
          <span style="font-size: 15px;" class="js" v-if="data.type === 'urgent'">紧急</span>
        </span>
        <i @click="$emit('action', {action: {label: '删除', key: 'del'}, data})" v-if="actions.del" class="iconfont icon-shanchu"></i>
      </div>
      <div class="info">
        <div class="time">{{data.year}}年</div>
        <div class="time">{{data.batch}}
        </div>
        <div v-if="data.status === 'creating'">
          <div>{{data.orgIds ? data.orgIds.split(',').length : 0}}个填报科室</div>
        </div>
        <div v-else>
          <div>{{data.countTotal}}元</div>
        </div>
      </div>
      <div class="action">
        <div class="btn" v-for="action in btns.filter(v => v.key !== 'del')" :key="action.key" @click="$emit('action', { action, data })">{{action.label}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: () => ({}) }
  },
  computed: {
    btns () {
      if (this.data && this.data.actions) {
        const actions = this.data.actions.filter(v => !['add_detail'].includes(v.key))
        if (this.data.status !== 'creating') {
          return [
            { label: '查看', key: 'details' },
            { label: '会议材料', key: 'file' },
            ...actions
          ]
        } else {
          return actions
        }
      } else {
        return []
      }
    },
    actions () {
      let actions = {}
      ;(this.data.actions || []).forEach(item => actions[item.key] = true)
      return actions
    }
  }
}
</script>
